import Typography from '@mui/material/Typography';
import {
    Breadcrumbs,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    InputLabel,
    Link,
    Modal,
    Stack,
    TextField,
    Tooltip
} from '@mui/material';
import {FC, useEffect, useState} from "react";
import DashboardTitle from "../../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../../paths";
import {useOperation} from "../../../contexts/OperationContext";
import axios from "../../../services/APIService";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent, {timelineOppositeContentClasses} from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import {Edit} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {DeleteFilled} from "@ant-design/icons";
import Button from "@mui/material/Button";
import {OperatorType} from "../../../types/operation";
import {convertKeysToCamelCase} from "../../../utils/formatting";
import {TextBaseCard} from "../../../components/BaseCard";

interface TrackingData {
    id: number,
    title: string,
    description: string,
    date: string,
    type: string,
    operator: OperatorType | null,
    value?: string,
    unit?: string,
}

interface TrackingDataDailySummary {
    id: number,
    date: string,
    operationId: number,
    summary: string,
    trackingData: TrackingData[]
}


const History = () => {
    const {operation} = useOperation()
    const [unsummarizedTrackingData, setUnsummarizedTrackingData] = useState<TrackingData[]>([])
    const [trackingDataDailySummaries, setTrackingDataDailySummaries] = useState<TrackingDataDailySummary[]>([])
    const [trackingDataEditItem, setTrackingDataEditItem] = useState<TrackingData | null>(null)
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false)

    useEffect(() => {
        // TODO: Check that operation is not null as a standard part of workflow for these pages and remove need for
        //  these checks everywhere we want to use operation
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get('/operational_tracking/history/daily/', {params})
                .then(response => {
                    const data = convertKeysToCamelCase(
                        response.data
                            .sort(
                                (a: TrackingDataDailySummary, b: TrackingDataDailySummary) => new Date(b.date).getTime() - new Date(a.date).getTime())
                    )
                    setTrackingDataDailySummaries(data)
                })

            axios.get('/operational_tracking/history/unsummarized/', {params})
                .then(response => {
                    const data = convertKeysToCamelCase(
                        response.data
                            .sort(
                                (a: TrackingData, b: TrackingData) => new Date(b.date).getTime() - new Date(a.date).getTime())
                    )
                    setUnsummarizedTrackingData(data)
                })
        }
    }, [operation])

    const handleOpenEditModal = (trackingData: TrackingData) => {
        return () => {
            setTrackingDataEditItem(trackingData);
            setEditModalOpen(true);
        }
    }

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
        setTrackingDataEditItem(null);
    }

    const EditModal: FC<{ trackingDataItem: TrackingData | null }> = ({trackingDataItem}) => {
        const [trackingDataEditItemTitle, setTrackingDataEditItemTitle] = useState<string | null>(null)
        const [trackingDataEditItemDescription, setTrackingDataEditItemDescription] = useState<string | null>(null)
        const [trackingDataEditItemValue, setTrackingDataEditItemValue] = useState<string | null>(null)
        const [trackingDataEditItemUnit, setTrackingDataEditItemUnit] = useState<string | null>(null)

        useEffect(() => {
            if (trackingDataItem) {
                setTrackingDataEditItemTitle(trackingDataItem.title);
                setTrackingDataEditItemDescription(trackingDataItem.description);
                setTrackingDataEditItemValue(
                    trackingDataItem.value !== undefined ? trackingDataItem.value : null
                );
                setTrackingDataEditItemUnit(
                    trackingDataItem.unit !== undefined ? trackingDataItem.unit : null
                );
            }
        }, [trackingDataItem]);

        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            p: 0,
        }

        const handleDeleteTrackingData = (trackingDataItem: TrackingData) => {
            return () => {
                if (operation) {
                    const params = {operation_id: operation.id}
                    axios.delete(`operational_tracking/tracking_data/${trackingDataItem.id}/`, {params})
                        .then(
                            () => {
                                axios.get('/operational_tracking/history/daily/', {params})
                                    .then(response => {
                                        setTrackingDataDailySummaries(response.data)
                                    })
                            }
                        )
                        .then(
                            () => {
                                handleCloseEditModal();
                            }
                        )
                }
            }
        }

        const handleUpdateTrackingData = (trackingDataItem: TrackingData) => {
            return () => {
                if (operation) {
                    const params = {operation_id: operation.id}
                    const data = {
                        title: trackingDataEditItemTitle,
                        description: trackingDataEditItemDescription,
                        value: trackingDataEditItemValue,
                        unit: trackingDataEditItemUnit,
                    }
                    axios.patch(`operational_tracking/tracking_data/${trackingDataItem.id}/`, data, {params})
                        .then(
                            () => {
                                axios.get('/operational_tracking/history/daily/', {params})
                                    .then(response => {
                                        setTrackingDataDailySummaries(response.data)
                                    })
                            }
                        )
                        .then(
                            () => {
                                handleCloseEditModal();
                            }
                        )
                }
            }
        }

        if (!trackingDataItem) {
            return null
        }

        return (<Modal
                open={editModalOpen}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item width={'100%'}>
                        <Card
                            sx={{
                                width: '100%',
                                background: '#FFFFFF',
                            }}
                        >
                            <CardHeader title={"Edit History Item"} sx={{p: '24px'}}/>
                            <Divider/>
                            <CardContent>
                                <Stack spacing={4}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="edit-tracking-data-title">
                                            Title
                                        </InputLabel>
                                        <TextField
                                            id="edit-tracking-data-title"
                                            value={trackingDataEditItemTitle}
                                            onChange={(e) => {
                                                setTrackingDataEditItemTitle(e.target.value)
                                            }}
                                        />
                                    </Stack>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="edit-tracking-data-description">
                                            Description
                                        </InputLabel>
                                        <TextField
                                            minRows={3}
                                            multiline
                                            id="edit-tracking-data-description"
                                            value={trackingDataEditItemDescription}
                                            onChange={(e) => {
                                                setTrackingDataEditItemDescription(e.target.value)
                                            }}
                                        />
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="edit-tracking-data-value">
                                                Value
                                            </InputLabel>
                                            <TextField
                                                id="edit-tracking-data-value"
                                                value={trackingDataEditItemValue}
                                                onChange={(e) => {
                                                    setTrackingDataEditItemValue(e.target.value)
                                                }}
                                            />
                                        </Stack>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="edit-tracking-data-unit">
                                                Unit
                                            </InputLabel>
                                            <TextField
                                                id="edit-tracking-data-unit"
                                                value={trackingDataEditItemUnit}
                                                onChange={(e) => {
                                                    setTrackingDataEditItemUnit(e.target.value)
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </CardContent>
                            <Divider/>
                            <CardActions>
                                <Stack direction="row" alignItems="center" justifyContent="space-between"
                                       sx={{width: 1}}>
                                    <Tooltip title="Delete Event" placement="top">
                                        <DeleteFilled onClick={handleDeleteTrackingData(trackingDataItem)}/>
                                    </Tooltip>
                                    <Stack direction="row" spacing={1} sx={{px: 1.5, py: 0.75}}>
                                        <Button color="error" size="small" onClick={handleCloseEditModal}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" size="small"
                                                onClick={handleUpdateTrackingData(trackingDataItem)}>
                                            Save
                                        </Button>
                                    </Stack>
                                </Stack>
                            </CardActions>
                        </Card>
                    </Grid>
                </Box>
            </Modal>
        )
    }

    const TrackingDataDailySummaryTimelineEvent: FC<{
        trackingDataDailySummary: TrackingDataDailySummary,
        finalItem: boolean,
    }> = ({
              trackingDataDailySummary,
              finalItem
          }) => {
        const [displayTrackingDataDetails, setDisplayTrackingDataDetails] = useState<boolean>(false)

        const handleToggleDisplayTrackingDataDetails = () => {
            setDisplayTrackingDataDetails(!displayTrackingDataDetails)
        }

        return (
            <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                    {trackingDataDailySummary.date}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot/>
                    {finalItem ? <></> : <TimelineConnector/>}
                </TimelineSeparator>
                <TimelineContent>
                    <Typography variant="body1">{trackingDataDailySummary.summary}</Typography>
                    {
                        displayTrackingDataDetails
                            ?
                            <>
                                <Button onClick={handleToggleDisplayTrackingDataDetails}>Hide Details</Button>
                                {
                                    trackingDataDailySummary.trackingData.map((trackingData) => (
                                        <TrackingDataInfo
                                            trackingData={trackingData}
                                        />
                                    ))
                                }
                            </>
                            :
                            <Button onClick={handleToggleDisplayTrackingDataDetails}>Show Details</Button>
                    }
                </TimelineContent>

            </TimelineItem>
        )
    }

    const TrackingDataInfo: FC<{
        trackingData: TrackingData
    }> = ({
              trackingData
          }) => {
        return (
            <>
                <Typography variant="h6" sx={{fontWeight: 'bold', verticalAlign: "middle"}}>
                    {trackingData.title} <Edit
                    color={"action"}
                    fontSize={"small"} onClick={handleOpenEditModal(trackingData)}
                />
                </Typography>
                {/*{*/}
                {/*    trackingData.value && trackingData.unit*/}
                {/*        ?*/}
                {/*        <Typography variant={"body1"}>{+trackingData.value} {trackingData.unit}</Typography>*/}
                {/*        :*/}
                {/*        <></>*/}
                {/*}*/}
                <Typography variant="body1">{trackingData.description}</Typography>
                {
                    trackingData.operator !== null
                        ?
                        <Typography variant="body2" color={"gray"}>Logged
                            by {trackingData.operator.firstName} {trackingData.operator.lastName}</Typography>
                        :
                        <></>
                }
            </>
        );
    }

    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.RESULTS.INDEX}>
                            Results
                        </Link>
                        <Typography color="textPrimary">
                            History
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"History"}/>
                <Grid item width={'100%'}>
                    {
                        unsummarizedTrackingData.length > 0
                            ?
                            <TextBaseCard title={"Unsummarized"}>
                                {
                                    unsummarizedTrackingData.map((trackingData) => (
                                        <TrackingDataInfo
                                            trackingData={trackingData}
                                        />
                                    ))
                                }
                            </TextBaseCard>
                            :
                            <>
                            </>
                    }
                </Grid>
                <Grid item width={'100%'}>
                    <TextBaseCard title={"History"}>
                        <Timeline
                            sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                    flex: 0.2,
                                },
                            }}
                        >
                            {trackingDataDailySummaries.map((trackingDataDailySummary, index) => (
                                <TrackingDataDailySummaryTimelineEvent key={index}
                                                                       trackingDataDailySummary={trackingDataDailySummary}
                                                                       finalItem={index === trackingDataDailySummaries.length - 1}/>
                            ))}
                        </Timeline>
                    </TextBaseCard>
                </Grid>
            </Grid>
            <EditModal trackingDataItem={trackingDataEditItem}/>
        </>
    )
}

export default History
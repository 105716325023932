import React, {useState} from 'react';
import axios from '../../services/APIService';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Alert, TextField} from "@mui/material";
import Button from "@mui/material/Button";

const PasswordResetRequest = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post('/accounts/password-reset/', {email});
            setMessage(response.data.message);
        } catch (error) {
            // setMessage(error.response.data.email || 'Error occurred.');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '30px',
                    // border: '1px solid #111111',
                    // borderRadius: '4px',
                    background: '#FFFFFF'
                }}
            >
                <Typography component="h1" variant="h3">
                    Reset Password
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Reset Password
                    </Button>
                    {
                        message &&
                        // TODO: Fix expanding width when message is shown
                    <Alert severity="success">
                        {message}
</Alert>
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default PasswordResetRequest;

import Typography from '@mui/material/Typography';
import {
    Box,
    Breadcrumbs,
    Collapse,
    Grid,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from '@mui/material';
import {FC, useEffect, useState} from "react";
import DashboardTitle from "../../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../../paths";
import {useOperation} from "../../../contexts/OperationContext";
import axios from "../../../services/APIService";
import {DailyTask, DailyTaskStatusType, DailyTaskType} from "../../../types/planning/dailyTasks";
import {BaseCard, TextBaseCard} from "../../../components/BaseCard";
import Dot from "../../../components/Dot";
import {ColorProps} from "../../../types/extended";
import Button from "@mui/material/Button";
import Markdown from "react-markdown";

interface TodayTODORowType {
    type: DailyTaskType,
    title: string,
    description: string,
    notes: string,
    location: string,
    status: DailyTaskStatusType
}

type FreeformLogStatus = "EDITING" | "SUBMITTING" | "ERROR" | "SUCCESS"


const STATUS_COLOR_MAP = {
    "SCHEDULED": "warning",
    "COMPLETED_UNCHANGED": "success",
    "COMPLETED_WITH_CHANGE": "success",
    "NOT_DONE": "error",
    "POSTPONED": "error",
}

const STATUS_TEXT_MAP = {
    "SCHEDULED": "Scheduled",
    "COMPLETED_UNCHANGED": "Completed",
    "COMPLETED_WITH_CHANGE": "Completed",
    "NOT_DONE": "Not Done",
    "POSTPONED": "Postponed",
}

const TASK_TYPE_TO_TEXT_MAP: { [key in DailyTaskType]: string } = {
    "PLANTING": "Planting",
    "CATTLE_MOVE": "Cattle Move",
    "MEASURE_GROWTH": "Measure Growth",
}

const TodayTODORow: FC<TodayTODORowType> = ({type, title, description, location, notes, status}) => {
    const [open, setOpen] = useState(false);
    const statusColor = STATUS_COLOR_MAP[status]
    const statusText = STATUS_TEXT_MAP[status]

    return <>
        <TableRow>
            <TableCell key={2}>{title}</TableCell>
            <TableCell key={3}>{TASK_TYPE_TO_TEXT_MAP[type]}</TableCell>
            <TableCell key={4}>{location}</TableCell>
            <TableCell>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Dot color={statusColor as ColorProps}/>
                    <Typography>{statusText}</Typography>
                </Stack>
            </TableCell>
            <TableCell key={5}>
                <Button
                    aria-label="expand row"
                    onClick={() => setOpen(!open)}
                >
                    {open ? "Hide Details" : "Show Details"}
                </Button>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{padding: 0}} colSpan={5}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box style={{padding: "5px 20px"}}>
                        {
                            description
                                ?
                                <Box style={{padding: "10px 0 0 0"}}>
                                    <Typography component={"span"} fontWeight={"900"}>Details: </Typography>
                                    <Typography component={"span"}><Markdown>{description}</Markdown></Typography>
                                </Box>
                                :
                                <></>
                        }
                        {
                            status.slice(0, 9) !== "COMPLETED" && !notes
                                ?
                                <Box style={{padding: "10px 0"}}>
                                    <Button variant={"contained"} style={{margin: 5}}>Log Data</Button>
                                    <Button variant={"contained"} color={"warning"} style={{margin: 5}}>Log
                                        Issue</Button>
                                </Box>
                                :
                                <></>
                        }
                        {
                            notes.trim().length > 0
                                ?
                                <Box style={{padding: "10px 0"}}>
                                    <Typography component={"span"} fontWeight={"900"}>Notes: </Typography>
                                    <Typography component={"span"}>{notes}</Typography>
                                </Box>
                                :
                                <></>
                        }
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>
}

const Tracking = () => {
    const {operation} = useOperation()
    const [todayTasks, setTodayTasks] = useState<DailyTask[]>([])
    const [freeformLogText, setFreeformLogText] = useState<string>("")
    const [freeformLogStatus, setFreeformLogStatus] = useState<FreeformLogStatus>("EDITING")
    const triggerCall = (name: string) => {
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get(`/operational_tracking/app_triggered_call/`, {params})
        }
    }

    const isToday = (date: Date) => {
        const today = new Date();
        return (
            today.getFullYear() === date.getUTCFullYear()
            &&
            today.getMonth() === date.getUTCMonth()
            &&
            today.getDate() === date.getUTCDate()
        )
    }

    useEffect(() => {
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get('/planning/daily-tasks/', {params})
            .then(response => {
                const _todayTasks: DailyTask[] = response.data.daily_tasks.filter((task: DailyTask) => {
                    const taskDate = new Date(task.date);
                    return isToday(taskDate)
                })
                setTodayTasks(_todayTasks);
            })
        }
    }, [operation])

    if (!operation) {
        window.location.href = '/';
        return null
    }



    const submitFreeformLog = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setFreeformLogStatus("SUBMITTING")
        axios.post('/operational_tracking/submit_freeform_log/', {
            operation_id: operation.id,
            text: freeformLogText
        })
            .then(
                response => {
                    setFreeformLogStatus("SUCCESS")
                    setFreeformLogText("")
                },
                error => {
                    setFreeformLogStatus("ERROR")
                }
            )
    }

    const onChangeFreeformLogText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFreeformLogText(e.target.value);
        setFreeformLogStatus("EDITING");
    }

    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.RESULTS.INDEX}>
                            Results
                        </Link>
                        <Typography color="textPrimary">
                            Tracking
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"Tracking"}/>
                <Grid item width={'100%'}>
                    <BaseCard title={"Today"}>
                        {/*<Box*/}
                        {/*    sx={{p: '24px'}}*/}
                        {/*>*/}
                        {/*    <Markdown>*/}
                        {/*        {summary}*/}
                        {/*    </Markdown>*/}
                        {/*</Box>*/}
                        <TableContainer
                            sx={{
                                width: '100%',
                                overflowX: 'auto',
                                position: 'relative',
                                display: 'block',
                                maxWidth: '100%',
                            }}
                        >
                            <Table aria-labelledby="tableTitle" sx={{
                                maxWidth: '100%',
                                tableLayout: "auto",
                                // width: "auto"
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell key={2}>Activity</TableCell>
                                        <TableCell key={3}>Type</TableCell>
                                        <TableCell key={4}>Location</TableCell>
                                        <TableCell key={5}>Status</TableCell>
                                        <TableCell key={6}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        todayTasks.map((dailyTask: DailyTask, index) => {
                                            return <TodayTODORow key={index}
                                                                 description={dailyTask.description}
                                                                 title={dailyTask.title}
                                                                 type={dailyTask.type}
                                                                 status={dailyTask.status}
                                                                 location={dailyTask.location}
                                                                 notes={dailyTask.notes}
                                            />
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BaseCard>
                </Grid>
                <Grid item width={'100%'}>
                    <TextBaseCard title={"Freeform Logging"}>
                        <Box sx={{marginBottom: "12px"}}>
                            <TextField
                                id="outlined-multiline-static"
                                fullWidth
                                label="Describe your activities, observations, and results."
                                multiline
                                rows={5}
                                disabled={freeformLogStatus === "SUBMITTING"}
                                onChange={onChangeFreeformLogText}
                            />
                        </Box>
                        <Box>
                            <Button
                                variant={"contained"}
                                onClick={submitFreeformLog}
                                disabled={freeformLogStatus === "SUBMITTING" || freeformLogStatus === "ERROR" || freeformLogStatus === "SUCCESS"}
                            >
                                {freeformLogStatus === "EDITING" && "Submit"}
                                {freeformLogStatus === "SUBMITTING" && "Submitting..."}
                                {freeformLogStatus === "ERROR" && "Retry"}
                                {freeformLogStatus === "SUCCESS" && "Submitted"}
                            </Button>
                        </Box>
                    </TextBaseCard>
                </Grid>
                <Grid item width={'100%'}>
                    <TextBaseCard title={"Phone Logging"}>
                        <Box sx={{marginBottom: "12px"}}>
                            <Typography>Next call scheduled today at 6:00pm CT</Typography>
                        </Box>
                        <Box>
                            <Button variant={"contained"} onClick={() => triggerCall('rhys')}>Trigger call now</Button>
                        </Box>
                    </TextBaseCard>
                </Grid>
            </Grid>
        </>
    )
}

export default Tracking
import React, {useState} from 'react';
import axios from '../../services/APIService';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../auth/AuthContext";
import Box from "@mui/material/Box";
import {Alert, Grid, Link, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useCustomer} from "../../contexts/CustomerContext";
import {useOperation} from "../../contexts/OperationContext";
import Paths from "../../paths";

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState<null | string>(null);
    const {login, logout} = useAuth();
    const {getCustomer} = useCustomer();
    const {saveOperation} = useOperation();
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            logout()
            const response = await axios.post('/accounts/login/', {
                email,
                password,
            });
            login({
                email: response.data.email,
                authToken: response.data.authToken,
                isStaff: response.data.is_staff
            }); // Save token
            if (response.data.is_staff) {
                navigate('/admin')

            } else {
                await getCustomer()
                    .then(newCustomer => {
                        if (newCustomer && newCustomer.operations.length > 0) {
                            const newOperation = newCustomer.operations[0]
                            console.log("Setting Operation:", newOperation)
                            saveOperation(newOperation);
                        } else {
                            console.error('Error fetching operation')
                            saveOperation(null);
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching customer info:', error);
                        saveOperation(null);  // Reset operation on error
                    });
                navigate('/home')
            }

        } catch (error: any) {
            console.error('Login failed:', error);
            setErrorMessage(error.response?.data.error || 'Error occurred.')
        }
    };

    return (
        <div>
            {/*<Header/>*/}
            <Box sx={{
                marginTop: '50px'
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '30px',
                            // border: '1px solid #111111',
                            // borderRadius: '4px',
                            background: '#FFFFFF'
                        }}
                    >
                        <Typography component="h1" variant="h3">
                            Reset Password
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href={Paths.ACCOUNT_MANAGEMENT.PASSWORD_RESET_REQUEST} variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                            </Grid>
                            {
                                errorMessage &&
                                                    <Alert severity="error">
                        {errorMessage}
</Alert>


                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default Login;
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import axios from '../../services/APIService';
import Typography from "@mui/material/Typography";
import {Alert, Grid, IconButton, InputAdornment, Link, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Paths from "../../paths";

const PasswordResetConfirm = () => {
    const {uid, token} = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await axios.post('/accounts/password-reset-confirm/', {
                uidb64: uid,
                token,
                new_password: newPassword,
            });
            setMessage(response.data.message);
        } catch (error) {
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '30px',
                    // border: '1px solid #111111',
                    // borderRadius: '4px',
                    background: '#FFFFFF'
                }}
            >
                <Typography component="h1" variant="h3">
                    Set New Password
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="new_password"
                        label="New Password"
                        name="new_password"
                        autoFocus
                        type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                        onChange={(e) => setNewPassword(e.target.value)}
                        InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Reset Password
                    </Button>
                    {
                        message &&
                        // TODO: Fix expanding width when message is shown
                        <Alert severity="success">
                            {message}
                        </Alert>
                    }
                    <Grid container>
                        <Grid item xs>
                            <Link href={Paths.ACCOUNT_MANAGEMENT.LOGIN} variant="body2">
                                Login
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>

    );
};

export default PasswordResetConfirm;
